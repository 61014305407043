import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslocoModule } from "@jsverse/transloco";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule],
})
export class LoaderComponent {
  @Input() isFullActive = true;
  @Input() isIndicatorActive = false;
  @Input() isLoaderTextActive = false;
  @Input() isFullSmall = false;
  @Input() isInFlowActive = false;
  @Input() isMiddleActive = false;
}
